import {StandaloneAccount, StandaloneAccountFormData} from "../models/StandaloneAccount";
import {HoldingAssetSubclassDetails, HoldingSummary, HoldingType} from "../models/Holding";
import {
    Accounts,
    AssetsSummary,
    AssetTotals,
    emptyNetValueSummary,
    EquityCompensationSummary,
    GeneralInflows,
    LifeInsuranceSummary,
    NetValueSummary,
    PersonalAssets,
    SocialSecurities,
    TaxLiabilitySummary
} from "../models/Assets";
import {
    CreatePersonalAssetRequest,
    PersonalAsset,
    PersonalAssetDetailsType,
    UnsavedPersonalAsset
} from "../models/PersonalAsset";
import {ActiveFormAsset, ClientAssetsState, InEstateAssetTotals} from "../clientAssetsSlice";
import {PersonalLiability, PersonalLiabilityFormData, PersonalLiabilitySummary} from "../models/PersonalLiability";
import {
    InvestorGroupMember,
    InvestorGroupMemberType,
    InvestorGroupType,
    Member,
    MemberGroup,
    MemberGroupMember,
    PlanningPeriodType
} from "../../ClientManagement/models/InvestorGroupType";
import {GeneralInflow} from "../models/GeneralInflow";
import {
    LegalEntityFormData,
    LegalEntityOwnership,
    LegalEntityOwnershipFormData,
    LegalEntityOwnershipWriteModel,
    LegalEntityReadModel,
    MemberOwnership,
    MemberOwnershipFormData,
    MemberOwnershipWriteModel,
    NonClientMemberOwnershipFormData, OwnershipCategory,
    OwnershipDetailsFormData
} from "../models/Ownership";
import {
    DomesticTrustAccount,
    HeldAwayAccount,
    HeldAwayAccountHoldingsRequest, HeldAwayAccountSummary,
    InvestmentProgram,
    LegalAgreement
} from "../models/InvestmentProgram";
import {SocialSecurity, SocialSecurityBenefits} from "../models/SocialSecurity";
import {testInvestorGroupResponse} from "../../ClientManagement/TestUtils";
import {
    CreateLifeInsuranceRequest,
    LifeInsurance,
    lifeInsuranceTypes,
    UpdateLifeInsuranceRequest
} from "../models/LifeInsurance";
import {mapSoleMemberOwnership} from "../Ownership/mappers";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {AssetClass, AssetClassifications, AssetSubclass, AssetSubclassDetails} from "../models/AssetClassifications";
import {
    AutoCalculateVestingPeriodResponse,
    CalculateStockOptionRequest,
    CalculateVestingInfoResponse,
    EquityCompensationFormData,
    StockGrant,
    StockGrantType,
    StockOption,
    StockOptionsAndGrantsResponse,
    StockOptionType,
    StockOptionWriteModel,
    VestingPeriod,
    VestingPeriodWriteModel,
    VestingScheduleResponse
} from "../models/EquityCompensation";
import {mapPersonalLiabilityToFormData} from "../PersonalLiability/mappers";
import {v4 as generate_uuid} from "uuid";
import {
    PartiallyOwnedAccount, PartiallyOwnedInvestmentAccountWriteModel,
    PartiallyOwnedLegalAgreementReadModel
} from "../models/PartiallyOwnedInvestmentAccount";
import {BankingAccountReadModel} from "../models/BankingAccount";
import {BankingAccountsSummary} from "../models/BankingAccounts";


export const generateAssetClassifications: () => AssetClassifications = () => {
    return {
        riskAssetClasses: [
            generateAssetClass('Public Equity', [
                generateAssetSubclass({
                    assetSubclassName: "US Equity",
                    defaultAssetSubclassDetailId: 'subclass-details-id-us-broad-market',
                    assetSubclassDetails: [
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-us-small-cap',
                            assetSubclassDetailsName: "US Small Cap",
                            liquidityCategory: 'Liquid Risk Assets'
                        }),
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                            assetSubclassDetailsName: "US Broad Market",
                            liquidityCategory: 'Liquid Risk Assets'
                        }),
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-us-large-cap',
                            assetSubclassDetailsName: "US Large Cap",
                            liquidityCategory: 'Liquid Risk Assets'
                        })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Developed Ex-US Equity",
                    defaultAssetSubclassDetailId: 'subclass-details-id-developed-ex-us-broad-market',
                    assetSubclassDetails: [
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-developed-ex-us-broad-market',
                            assetSubclassDetailsName: "Developed Ex-US Broad Market",
                            liquidityCategory: 'Liquid Risk Assets',
                        })
                    ]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Does not contain any asset subclasses",
                    defaultAssetSubclassDetailId: 'also-completely-empty-id',
                    assetSubclassDetails: [
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'completely-empty-id',
                            assetSubclassDetailsName: "Completely empty",
                            liquidityCategory: null,
                        }),
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'also-completely-empty-id',
                            assetSubclassDetailsName: "Also completely empty",
                            liquidityCategory: null,
                        }),
                    ]
                })
            ]),
            generateAssetClass('Other Risk Assets', [
                generateAssetSubclass({
                    assetSubclassName: 'Other Risk Assets',
                    defaultAssetSubclassDetailId: 'subclass-details-id-other-risk-assets',
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-other-risk-assets',
                        assetSubclassDetailsName: 'Other Risk Assets',
                    })]
                }),
            ]),
            generateAssetClass('Some Other Risk Assets', [
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 1",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-other-risk-asset',
                        assetSubclassDetailsName: "Some Other Risk Asset 1",
                        liquidityCategory: 'Liquid Risk Assets',
                    })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 2",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-us-broad-market',
                        assetSubclassDetailsName: "Some Other Risk Asset 2",
                        liquidityCategory: 'Liquid Risk Assets',
                    })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 3",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-other',
                        assetSubclassDetailsName: "Some Other Risk Asset 3",
                        liquidityCategory: 'Other',
                    })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 4",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-equity-derivatives',
                        assetSubclassDetailsName: "Some Other Risk Asset 4",
                        liquidityCategory: 'Equity Derivatives',
                    })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 5",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-semi-liquid',
                        assetSubclassDetailsName: "Some Other Risk Asset 5",
                        liquidityCategory: 'Semi Liquid Risk Assets',
                    })]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Some Other Risk Assets 6",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-1-illiquid',
                        assetSubclassDetailsName: "Some Other Risk Asset 6",
                        liquidityCategory: 'Illiquid Risk Assets',
                    })]
                })
            ]),
            generateAssetClass("Does not contain any asset classes", [
                generateAssetSubclass({
                    assetSubclassName: "Part of an asset class that doesn't have any field values",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: []
                })
            ]),
            generateAssetClass("Other", [
                generateAssetSubclass({
                    assetSubclassName: "Part of an asset class that doesn't have any field values",
                    defaultAssetSubclassDetailId: null,
                    assetSubclassDetails: []
                })
            ]),
        ],
        riskControlAssetClasses: [
            generateAssetClass('Investment Grade Fixed Income', [
                generateAssetSubclass({
                    assetSubclassName: "Conventional Fixed Income",
                    defaultAssetSubclassDetailId: 'subclass-details-id-tax-exempt-intermediate-maturity-fixed-income',
                    assetSubclassDetails: [
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-tax-exempt-intermediate-maturity-fixed-income',
                            assetSubclassDetailsName: "Tax Exempt Intermediate Maturity Fixed Income",
                            liquidityCategory: 'Liquid Risk Control',
                        }),
                        generateAssetSubclassDetail({
                            assetSubclassDetailsId: 'subclass-details-id-taxable-dev-ex-us-long-maturity-fixed-income',
                            assetSubclassDetailsName: "Taxable Dev Ex-US Long Maturity Fixed Income",
                            liquidityCategory: 'Liquid Risk Control',
                        })]
                }),
                generateAssetSubclass({
                    assetSubclassName: 'Cash Money',
                    defaultAssetSubclassDetailId: 'subclass-details-id-cash',
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-cash',
                        assetSubclassDetailsName: 'Cash',
                        liquidityCategory: 'Liquid Risk Control',
                    })
                    ]
                }),
                generateAssetSubclass({
                    assetSubclassName: "Other Fixed Income",
                    defaultAssetSubclassDetailId: 'subclass-details-id-other-fixed-income',
                    assetSubclassDetails: [generateAssetSubclassDetail({
                        assetSubclassDetailsId: 'subclass-details-id-other-fixed-income',
                        assetSubclassDetailsName: "Some Other Fixed Income",
                        liquidityCategory: 'Liquid Risk Control',
                    })]
                })
            ]),
            generateAssetClass('Other Risk Control Assets', [generateAssetSubclass({
                assetSubclassName: 'Other Risk Control Assets',
                defaultAssetSubclassDetailId: 'subclass-details-id-other-risk-control-assets',
                assetSubclassDetails: [generateAssetSubclassDetail({
                    assetSubclassDetailsId: 'subclass-details-id-other-risk-control-assets',
                    assetSubclassDetailsName: 'Other Risk Control Assets',
                })]
            })])
        ]
    }
}

export const generateHoldingsSummaryWithData: () => HoldingSummary = () => {
    return {
        totalMarketValue: 73100.54,
        totalInvestableValue: 70100.54,
        totalTaxCost: 1232,
        unrealizedCapitalGainsTax: -1500,
        deferredTaxLiability: -27407,
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                id: 'us-small-cap-id',
                assetSubclassDetailsId: 'subclass-details-id-us-small-cap',
                assetSubclassDetailsName: "US Small Cap",
                productName: "US Small Cap",
                marketValue: 70000.14,
                investableValue: 67000.14,
                locked: false,
                concentrated: false
            }),
            generateHoldingAssetSubclassDetail({
                id: 'us-broad-market-id',
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: "US Broad Market",
                productName: "US Broad Market",
                marketValue: 100.1,
                taxCost: 50.25,
                investableValue: 3100.00,
                locked: false,
                concentrated: false
            }),
            generateHoldingAssetSubclassDetail({
                id: 'developed-ex-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-developed-ex-us-broad-market',
                assetSubclassDetailsName: "Developed Ex-US Broad Market",
                productName: "Developed Ex-US Broad Market",
                marketValue: 200.1,
                taxCost: 60.25,
                investableValue: 3400.00,
                locked: false,
                concentrated: false
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-risk-asset-id-1',
                assetSubclassDetailsId: 'subclass-details-id-1-other-risk-asset',
                assetSubclassDetailsName: "Some Other Risk Asset 1",
                productName: "Some Other Risk Asset 1",
                marketValue: 500.1,
                taxCost: 20.25,
                investableValue: 700.00,
            }),
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'completely-empty-id',
                assetSubclassDetailsName: "Completely empty",
            }),
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'also-completely-empty-id',
                assetSubclassDetailsName: "Also completely empty",
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-risk-asset-id-2',
                assetSubclassDetailsId: 'subclass-details-id-1-us-broad-market',
                assetSubclassDetailsName: "Some Other Risk Asset 2",
                productName: "Some Other Risk Asset 2",
                marketValue: 300.6,
                taxCost: 70.25,
                investableValue: 700.00,
            }),
            generateHoldingAssetSubclassDetail({
                id: 'tax-exempt-inter-matur-fixed-income-id',
                assetSubclassDetailsId: 'subclass-details-id-other-fixed-income',
                assetSubclassDetailsName: "Tax Exempt Intermediate Maturity Fixed Income",
                productName: "Tax Exempt Intermediate Maturity Fixed Income",
                taxCost: 3000.3,
                marketValue: 5000,
                investableValue: 4500
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-fixed-income-id',
                assetSubclassDetailsId: 'subclass-details-id-other-fixed-income',
                assetSubclassDetailsName: "Some Other Fixed Income",
                productName: "Some Other Fixed Income",
                taxCost: 3070.3,
                marketValue: 5500,
                investableValue: 5500
            })
        ],
    }
}

export const generateHoldingsSummaryWithProductName: () => HoldingSummary = () => {
    return {
        totalMarketValue: 73100.54,
        totalInvestableValue: 70100.54,
        totalTaxCost: 1232,
        unrealizedCapitalGainsTax: -1500,
        deferredTaxLiability: -27407,
        asOfDate: "2024-06-04",
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                id: 'id-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: "US Broad Market",
                accountNumber: "12345",
                productId: '123',
                productName: "ELM CLO LTD 144A FLTG SER 14-1A CL DR",
                marketValue: 100.1,
                taxCost: 50.25,
                investableValue: 3100.00,
                locked: false,
                concentrated: false,
                ticker: "ELM",
                cusip: "289247AQ1",
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'subclass-details-id-developed-ex-us-broad-market',
                assetSubclassDetailsName: "Developed Ex-US Broad Market",
                accountNumber: "678910",
                productId: '456',
                productName: "BUY TO OPEN REPO W/RBC DOMINION SE .2%",
                marketValue: 200.1,
                taxCost: 60.25,
                investableValue: 3400.00,
                locked: false,
                concentrated: false,
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                id: 'tax-exempt-inter-matur-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-tax-exempt-intermediate-maturity-fixed-income",
                assetSubclassDetailsName: "Tax Exempt Intermediate Maturity Fixed Income",
                accountNumber: "34567",
                productId: '789',
                productName: "Tax Exempt Intermediate Maturity Fixed Income",
                taxCost: 3000.3,
                marketValue: 5000,
                investableValue: 4500,
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-other-fixed-income",
                assetSubclassDetailsName: "Some Other Fixed Income",
                accountNumber: "67890",
                productId: '012',
                productName: "Some Other Fixed Income",
                taxCost: 3070.3,
                marketValue: 5500,
                investableValue: 5500
            })
        ],
    }
};

export const generateLegalAgreementHoldingsSummary: () => HoldingSummary = () => {
    return {
        totalMarketValue: 73100.54,
        totalInvestableValue: 70100.54,
        totalTaxCost: 1232,
        unrealizedCapitalGainsTax: -1500,
        deferredTaxLiability: -27407,
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                id: 'id-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: "US Broad Market",
                accountNumber: "12345",
                productId: '123',
                productName: "ELM CLO LTD 144A FLTG SER 14-1A CL DR",
                marketValue: 100.1,
                taxCost: 50.25,
                investableValue: 3100.00,
                locked: false,
                concentrated: false,
                ticker: "ELM",
                cusip: "289247AQ1",
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                id: 'id-ex-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-developed-ex-us-broad-market',
                assetSubclassDetailsName: "Developed Ex-US Broad Market",
                accountNumber: "678910",
                productId: '456',
                productName: "BUY TO OPEN REPO W/RBC DOMINION SE .2%",
                marketValue: 200.1,
                taxCost: 60.25,
                investableValue: 3400.00,
                locked: false,
                concentrated: true,
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                id: 'tax-exempt-inter-matur-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-tax-exempt-intermediate-maturity-fixed-income",
                assetSubclassDetailsName: "Tax Exempt Intermediate Maturity Fixed Income",
                accountNumber: "34567",
                productId: '789',
                productName: "Tax Exempt Intermediate Maturity Fixed Income",
                taxCost: 3000.3,
                marketValue: 5000,
                investableValue: 4500,
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-other-fixed-income",
                assetSubclassDetailsName: "Some Other Fixed Income",
                accountNumber: "67890",
                productId: '012',
                productName: "Some Other Fixed Income",
                taxCost: 3070.3,
                marketValue: 5500,
                investableValue: 5500
            })
        ],
    }
};

export function generateAssetClass(assetClassName: string, assetSubclasses: AssetSubclass[]): AssetClass {
    return {
        assetClassName,
        assetSubclasses
    }
}

export function generateAssetSubclass(assetSubclass: Partial<AssetSubclass>): AssetSubclass {
    return {
        assetSubclassName: "Asset Subclass Name",
        defaultAssetSubclassDetailId: "",
        assetSubclassDetails: [generateAssetSubclassDetail()],
        ...assetSubclass
    }
}

export function generateAssetSubclassDetail(assetSubclassDetail: Partial<AssetSubclassDetails> = {}): AssetSubclassDetails {
    return {
        assetSubclassDetailsId: `subclass-details-id-${Math.random()}`,
        assetSubclassDetailsName: 'Subclass Details Name',
        liquidityCategory: null,
        ...assetSubclassDetail
    };
}

export function generateHoldingAssetSubclassDetail(assetSubclassDetail: Partial<HoldingAssetSubclassDetails> = {}): HoldingAssetSubclassDetails {
    return {
        assetSubclassDetailsId: `subclass-details-id-${getRandomNumber()}`,
        assetSubclassDetailsName: 'Subclass Details Name',
        accountNumber: "12345",
        productName: 'Subclass Product Name',
        marketValue: null,
        marketEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        taxCost: null,
        investableValue: null,
        locked: false,
        concentrated: false,
        ...assetSubclassDetail
    };
}

export function generateHoldingsSummary(overrides?: Partial<HoldingSummary>): HoldingSummary {
    return {
        totalMarketValue: null,
        totalInvestableValue: null,
        unrealizedCapitalGainsTax: null,
        deferredTaxLiability: null,
        totalTaxCost: null,
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: 'US Broad Market',
            }),
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'subclass-details-id-tax-exempt-intermediate-maturity-fixed-income',
                assetSubclassDetailsName: 'Tax Exempt Intermediate Maturity Fixed Income',
            })
        ],
        ...overrides
    }
}

export function generateLargeHoldingsSummary(overrides?: Partial<HoldingSummary>): HoldingSummary {
    let multiplePOIA = createPartiallyOwnedAccounts(generateHoldingAssetSubclassDetail(),10000);
    return {
        totalMarketValue: null,
        totalInvestableValue: null,
        unrealizedCapitalGainsTax: null,
        deferredTaxLiability: null,
        totalTaxCost: null,
        totalInvestablePresentValue:null,
        allHoldings: [
            ...multiplePOIA
        ],
        marketValue: {
                    inEstateValue: 104550.72,
                        outOfEstateValue: 104550.72,
                        totalValue: 209101.44
                     },
        asOfDate: "2023-08-14",
        ...overrides
    }
}

export function generateStandaloneAccount(overrides: Partial<StandaloneAccount> = {}): StandaloneAccount {
    return {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        profileId: 'test-profile-id',
        name: 'test-account-name',
        institution: 'Fidelity',
        entityType: 'Taxable Account',
        accountNumber: '1234567890',
        asOfDate: '2020-06-16',
        taxStatus: 'Taxable',
        inEstateValue: 0,
        memberOwnerships: [generateMemberOwnership({
            member: generateMember({
                id: 'john-doe-member-id',
            }),
            percentage: 100,
            isRevocableTrust: false,
        })],
        ownershipCategory: 'Sole',
        legalEntityOwnerships: [],
        doesPermitBeneficiary: true,
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: null,
        holdings: generateHoldingsSummary(overrides?.holdings),
        personalLiabilities: [],
        marketValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        ordinal: -1,
        ...overrides
    };
}

export function generatePartiallyOwnedInvestmentAccount(
    partiallyOwnedInvestmentAccountOverrides?: Partial<PartiallyOwnedLegalAgreementReadModel>): PartiallyOwnedLegalAgreementReadModel {
    return {
        marketValue: 0,
        profileId: "test-profile-id",
        legalAgreementName: 'Partially Owned Investment Account',
        legalAgreementNumber: 'LG123456',
        taxStatus: 'Taxable',
        legalAgreementTypeCode: "ROTH-IRA",
        institution: 'Northern Trust - POIA',
        entityType: 'POIA Trust Account',
        id: `some-legal-agreement-id-${getRandomNumber()}`,
        permitBeneficiary: true,
        personalLiabilities: [],
        marketEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        asOfDate: '01/02/2024',
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: true,
        legalEntityOwnerships: [generateLegalEntityOwnership()],
        memberOwnerships: [generateMemberOwnership()],
        ownershipCategory: "Sole",
        partiallyOwnedAccounts: [generatePartiallyOwnedAccount({
            accountNumber: '123456789',
            financialAccountPersistentId: '456763'
        })],
        holdings: {
            totalMarketValue: 100,
            totalInvestableValue: 100,
            totalTaxCost: null,
            unrealizedCapitalGainsTax: -6042.171000,
            totalInvestablePresentValue: 0,
            deferredTaxLiability: null,
            allHoldings: [],
            marketValue: {
                inEstateValue: 104550.72,
                outOfEstateValue: 104550.72,
                totalValue: 209101.44
            },
            asOfDate: "2023-08-14"
            },
        ordinal: -1,
        ...partiallyOwnedInvestmentAccountOverrides
    }
}

export function generatePartiallyOwnedInvestmentAccountWriteModel(
    partiallyOwnedInvestmentAccountOverrides?: Partial<PartiallyOwnedInvestmentAccountWriteModel>): PartiallyOwnedInvestmentAccountWriteModel {
    return {
        profileId: "test-profile-id",
        legalAgreementName: 'Partially Owned Investment Account',
        legalAgreementNumber: 'LG0263148',
        taxStatus: 'Taxable',
        legalAgreementTypeCode: "ROTH-IRA",
        institution: 'Northern Trust - POIA',
        entityType: 'POIA Trust Account',
        id: `some-legal-agreement-id-${getRandomNumber()}`,
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: true,
        legalEntityOwnerships: [generateLegalEntityOwnership()],
        memberOwnerships: [generateMemberOwnership()],
        partiallyOwnedAccounts: [generatePartiallyOwnedAccount()],
        ownershipCategory: "Partial",
        ordinal: -1,
        permitBeneficiary: true,
        ...partiallyOwnedInvestmentAccountOverrides
    }
}

export function generateStandaloneAccountFormData(overrides: Partial<StandaloneAccountFormData> = {}): StandaloneAccountFormData {
    return {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: 'test-account-name',
        institution: 'Fidelity',
        entityType: 'Taxable Account',
        accountNumber: '1234567890',
        asOfDate: '2020-06-16',
        taxStatus: 'Taxable',
        ownershipCategory: 'Sole',
        soleOwnerType: 'Individual',
        memberOwnerships: [generateMemberOwnershipFormData({
            memberId: 'john-doe-member-id',
            percentage: "100",
            isRevocableTrust: false,
        })],
        nonClientMemberOwnerships: [],
        legalEntityOwnerships: [],
        doesPermitBeneficiary: true,
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: null,
        holdings: generateHoldingsSummary(overrides?.holdings),
        ...overrides
    };
}

export function generateUnsavedPersonalAsset(overrides: Partial<UnsavedPersonalAsset> = {}): UnsavedPersonalAsset {
    return {
        description: 'test-description',
        presentValue: 0,
        type: "Primary Home",
        ownershipCategory: 'Sole',
        memberOwnerships: [generateMemberOwnershipWriteModel({
            memberId: 'john-doe-member-id',
            percentage: 100,
            isRevocableTrust: false,
        })],
        legalEntityOwnerships: [],
        ...overrides
    }
}

export function generatePersonalAsset(overrides: Partial<PersonalAsset> = {}): PersonalAsset {
    return {
        id: "test-personal-asset-id",
        profileId: 'test-profile-id',
        description: 'test-description',
        presentValue: 0,
        type: "Primary Home",
        ownershipCategory: 'Sole',
        personalLiabilities: [],
        inEstateValue: 0,
        presentEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        memberOwnerships: [generateMemberOwnership({
            member: generateMember({id: 'john-doe-member-id'}),
            percentage: 100,
            isRevocableTrust: false,
        })],
        legalEntityOwnerships: [],
        ordinal: -1,
        ...overrides
    };
}

export function generateCreatePersonalAssetRequest(overrides: Partial<CreatePersonalAssetRequest> = {}): CreatePersonalAssetRequest {
    return {
        profileId: 'test-profile-id',
        description: 'test-description',
        presentValue: 0,
        type: "Primary Home",
        ownershipCategory: 'Sole',
        memberOwnerships: [generateMemberOwnershipWriteModel({
            memberId: 'john-doe-member-id',
            percentage: 100,
            isRevocableTrust: false,
        })],
        legalEntityOwnerships: [],
        ...overrides
    };
}

export function generatePersonalAssetDetails(overrides?: Partial<PersonalAssetDetailsType>): PersonalAssetDetailsType {
    return {
        type: 'Primary Home',
        description: '',
        presentValue: 0,
        ...overrides
    };
}

export function generateGeneralInflow(overrides: Partial<GeneralInflow> = {}): GeneralInflow {
    const memberOwnership = generateMemberOwnership({
        member: {
            id: "test-member-id",
            name: "test member id"
        },
    });

    return {
        id: "test-general-inflow-id",
        profileId: "test-profile-id",
        description: "Primary Salary",
        type: "Salary",
        grossAnnualFlow: 123_456,
        taxRate: 43.22,
        netAnnualFlow: 1_500,
        yearsUntilFlow: 7,
        yearsOfFlow: 12,
        startDate: '2021-10-14',
        endDate: '2033-10-14',
        isInflowWillFundLifestyleGoal: true,
        isHighRisk: false,
        willAdjustWithInflation: true,
        presentValue: 16_208,
        memberOwnerships: [memberOwnership],
        inflowReserveLength: 0,
        totalPresentValue: 0,
        interestRate: 0,
        lifestyleGoalAligned: 0,
        excessFutureInflow: 0,
        inflowReservePresentValue: 0,
        ordinal: -1,
        isDisclosureReviewed: false,
        ...overrides
    }
}

export function generateSocialSecurity(overrides: Partial<SocialSecurity> = {}): SocialSecurity {
    return {
        id: "social-security-id",
        profileId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        startAge: 62,
        grossAnnualFlow: 50000,
        netAnnualFlow: 31500,
        yearsUntilFlow: 5,
        yearsOfFlow: 30,
        presentValue: 80000,
        taxRate: 20,
        interestRate: 0.0067,
        memberOwnership: mapSoleMemberOwnership(testInvestorGroupResponse.primaryMember?.id),
        isInflowWillFundLifestyleGoal: true,
        lifestyleGoalAligned: 50000,
        excessFutureInflow: 30000,
        inflowReservePresentValue: 1000,
        inflowReserveLength: null,
        ordinal: -1,
        ...overrides
    }
}

export function generateAccounts(accountOverrides?: Partial<Accounts>): Accounts {
    return {
        totalPresentValue: 0,
        totalInvestableValue: 0,
        totalRiskControlPresentValue: 0,
        totalInvestableTaxValue: 0,
        totalInvestableTaxDeferredValue: 0,
        totalPresentTaxLiabilityForTaxableAccounts: 0,
        totalPresentTaxLiabilityForDeferredAccounts: 0,
        totalPresentTaxLiabilityForAllAccounts: 0,
        totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: 0,
        taxableRatio: 1,
        data: [],
        ...accountOverrides
    };
}

export function generatePersonalAssets(personalAssetOverrides?: Partial<PersonalAssets>): PersonalAssets {
    return {
        totalPresentValue: 0,
        data: [],
        ...personalAssetOverrides
    };
}

export function generateGeneralInflows(generalInflowsOverrides?: Partial<GeneralInflows>): GeneralInflows {
    return {
        totalPresentValue: 0,
        data: [],
        ...generalInflowsOverrides
    };
}

export function generateSocialSecurities(socialSecurityOverrides?: Partial<SocialSecurities>): SocialSecurities {
    return {
        totalPresentValue: 0,
        data: [],
        ...socialSecurityOverrides
    };
}

export function generateLifeInsurance(overrides?: Partial<LifeInsurance>): LifeInsurance {
    return {
        id: "",
        profileId: "",
        description: "",
        type: lifeInsuranceTypes.TERM,
        insuredMemberId: "test-primary-member-id",
        deathBenefitValue: 0,
        insuranceCompanyName: "",
        ownershipCategory: "Sole",
        issueDate: "2022-12-11T12:00:00.000Z",
        cashValue: 0,
        term: 10,
        expirationDate: '',
        isCashValueWillFundGoals: false,
        memberOwnerships: [generateMemberOwnership({
            percentage: 30,
            isInEstateMember: true
        }), generateMemberOwnership({percentage: 30, isInEstateMember: true})],
        legalEntityOwnerships: [generateLegalEntityOwnership({percentage: 40})
        ],
        beneficiaries: [
            generateLifeInsuranceBeneficiaries("owner-member-id")
        ],
        ordinal: -1,
        ...overrides
    };
}

export function generateLifeInsuranceSummary(overrides?: Partial<LifeInsuranceSummary>): LifeInsuranceSummary {
    return {
        data: [],
        ...overrides
    };
}

export function generateEquityCompensationSummary(overridesEquity?: Partial<EquityCompensationSummary>): EquityCompensationSummary {
    return {
        data: [],
        totalPresentValue: 0,
        totalAfterTaxUnVestedValue: 0,
        totalAfterTaxVestedValue: 0,
        ...overridesEquity
    };
}

export function generateLifeInsuranceRequest(overrides?: Partial<CreateLifeInsuranceRequest | UpdateLifeInsuranceRequest>): (CreateLifeInsuranceRequest | UpdateLifeInsuranceRequest) {
    return {
        id: 'life-insurance-id',
        profileId: 'test-profile-id',
        description: 'Life Insurance updated',
        type: lifeInsuranceTypes.WHOLE,
        insuredMemberId: 'test-partner-member-id',
        deathBenefitValue: 1_000,
        insuranceCompanyName: 'updated insurance company',
        ownershipCategory: 'Partial',
        issueDate: "2022-12-11T12:00:00.000Z",
        cashValue: 10000000,
        term: 10,
        expirationDate: '',
        isCashValueWillFundGoals: false,
        memberOwnerships: [
            generateMemberOwnershipWriteModel({
                memberId: "test-primary-member-id",
                percentage: 50,
            }),
            generateMemberOwnershipWriteModel({
                memberId: "test-partner-member-id",
                percentage: 50,
            }),
        ],
        legalEntityOwnerships: [],
        beneficiaries: [],
        ...overrides
    };
}

export function generatePersonalLiabilityFormData(overrides?: Partial<PersonalLiabilityFormData>): PersonalLiabilityFormData {
    return {
        ...mapPersonalLiabilityToFormData(generatePersonalLiability()),
        ...overrides
    };
}

export function generatePersonalLiability(personalLiabilityOverrides?: Partial<PersonalLiability>): PersonalLiability {
    return {
        id: "",
        profileId: "",
        description: "",
        liabilityType: "Personal",
        type: "Mortgage",
        collateralId: null,
        loanBalance: 0,
        institution: "",
        paymentFrequency: "Monthly",
        loanType: "Fixed",
        interestOnly: true,
        interestRate: 0.00,
        maturityDate: "2022-05-17",
        ownershipCategory: "Sole",
        memberOwnerships: [generateMemberOwnership()],
        legalEntityOwnerships: [],
        loanBalanceEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        ordinal: -1,
        ...personalLiabilityOverrides
    };
}

export function generatePersonalLiabilitySummary(personalLiabilityOverrides?: Partial<PersonalLiabilitySummary>): PersonalLiabilitySummary {
    return {
        ...generatePersonalLiability(personalLiabilityOverrides),
        collateral: {
            description: "",
            presentValue: 0,
            ...personalLiabilityOverrides?.collateral
        },
        ...personalLiabilityOverrides
    };
}

export function generateInvestmentProgram(investmentProgramOverrides?: Partial<InvestmentProgram> | null): InvestmentProgram {
    return {
        name: "Some investment program name from the generator",
        totalMarketValue: 0,
        legalAgreements: [],
        heldAwayAccounts: [],
        id: "",
        portfolioManagerName: "some portfolio manager name",
        status: "",
        lastUpdatedDate: "",
        ...investmentProgramOverrides
    };
}

export function generateDomesticTrustAccount(overrides?: Partial<DomesticTrustAccount>): DomesticTrustAccount {
    return {
        accountNumber: '123456789',
        financialAccountPersistentId: '456763',
        financialAccountManagementCode: "",
        accountStatus: 'Open',
        marketValue: 0,
        asOfDate: '09/01/2023',
        ...overrides
    };
}

export function generatePartiallyOwnedAccount(overrides?: Partial<PartiallyOwnedAccount>): PartiallyOwnedAccount {
    return {
        accountNumber: '123456789',
        financialAccountPersistentId: '456763',
        accountStatus: 'Open',
        financialAccountManagementCode: 'LMA',
        marketValue: 100,
        asOfDate: '04/01/2024',
        ...overrides
    };
}

export function generateLegalAgreement(legalAgreementOverrides?: Partial<LegalAgreement>): LegalAgreement {
    return {
        profileId: "",
        id: `some-legal-agreement-id-${getRandomNumber()}`,
        name: 'XYZ Shares Account',
        legalAgreementNumber: 'LG0263148',
        legalAgreementTypeCode: "ROTH-IRA",
        accountNumbers: ['123456789', '223456789', '323456789'],
        financialAccountPersistentIds: ['456763', '546432', '234553'],
        institution: 'Northern Trust',
        entityType: 'Trust Account',
        taxStatus: 'Taxable',
        permitBeneficiary: true,
        asOfDate: '2022-01-08',
        marketValue: 1000,
        investableValue: 0,
        marketEstateValue: {
            inEstateValue: 1000,
            outOfEstateValue: 0,
            totalValue: 1000
        },
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: true,
        legalEntityOwnerships: [],
        memberOwnerships: [generateMemberOwnership()],
        ownershipCategory: "Sole",
        personalLiabilities: [],
        domesticTrustAccounts: [
            generateDomesticTrustAccount({
                accountNumber: '123456789',
                financialAccountPersistentId: '456763'
            }),
            generateDomesticTrustAccount({
                accountNumber: '223456789',
                financialAccountPersistentId: '546432'
            }),
            generateDomesticTrustAccount({
                accountNumber: '323456789',
                financialAccountPersistentId: '234553'
            })
        ],
        ordinal: -1,
        ...legalAgreementOverrides
    }
}

export function generatePartiallyOwnedLegalAgreement(legalAgreementOverrides?: Partial<PartiallyOwnedLegalAgreementReadModel>): PartiallyOwnedLegalAgreementReadModel {
    return {
        marketValue: 0,
        profileId: "",
        id: `some-legal-agreement-id-${getRandomNumber()}`,
        legalAgreementName: 'XYZ Shares Account',
        legalAgreementNumber: 'LG0263148',
        legalAgreementTypeCode: "ROTH-IRA",
        institution: 'Northern Trust',
        entityType: 'Trust Account',
        taxStatus: 'Taxable',
        permitBeneficiary: true,
        asOfDate: '2022-02-08',
        marketEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        holdings: {
        totalMarketValue: 100,
            totalInvestableValue: 100,
            totalTaxCost: null,
            unrealizedCapitalGainsTax: -6042.171000,
            totalInvestablePresentValue: 0,
            deferredTaxLiability: null,
            allHoldings: [],
            marketValue: {
            inEstateValue: 104550.72,
                outOfEstateValue: 104550.72,
                totalValue: 209101.44
             },
        asOfDate: "2023-08-14"
    },
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: true,
        legalEntityOwnerships: [generateLegalEntityOwnership({percentage: 40})
        ],
        memberOwnerships: [generateMemberOwnership({percentage:60})],
        ownershipCategory: "Partial",
        personalLiabilities: [],
        partiallyOwnedAccounts: [
            generatePartiallyOwnedAccount({
                accountNumber: '123456789',
                financialAccountPersistentId: '456763'
            }),
            generatePartiallyOwnedAccount({
                accountNumber: '223456789',
                financialAccountPersistentId: '546432'
            }),
            generatePartiallyOwnedAccount({
                accountNumber: '323456789',
                financialAccountPersistentId: '234553'
            })
        ],
        ordinal: -1,
        ...legalAgreementOverrides
    }
}

function createPartiallyOwnedAccounts<T>(element:T, count:number):T[]{
    return Array(count).fill(element);
}

export function generateLargePartiallyOwnedLegalAgreement(legalAgreementOverrides?: Partial<PartiallyOwnedLegalAgreementReadModel>): PartiallyOwnedLegalAgreementReadModel {
    let multiplePOIA = createPartiallyOwnedAccounts(generatePartiallyOwnedAccount(),10000);
    return {
        marketValue: 0,
        profileId: "",
        id: `some-legal-agreement-id-${getRandomNumber()}`,
        legalAgreementName: 'XYZ Shares Account',
        legalAgreementNumber: 'LG0263148',
        legalAgreementTypeCode: "ROTH-IRA",
        institution: 'Northern Trust',
        entityType: 'Trust Account',
        taxStatus: 'Taxable',
        permitBeneficiary: true,
        asOfDate: '2022-02-08',
        marketEstateValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        holdings:generateLargeHoldingsSummary(),
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: true,
        legalEntityOwnerships: [generateLegalEntityOwnership({percentage: 40})
        ],
        memberOwnerships: [generateMemberOwnership({percentage:60})],
        ownershipCategory: "Partial",
        personalLiabilities: [],
        partiallyOwnedAccounts:[...multiplePOIA],
        ordinal: -1,
        ...legalAgreementOverrides
    }

}

export function generateBankingAccount(bankingAccountOverrides?: Partial<BankingAccountReadModel>) : BankingAccountReadModel[] {
    return[
        {
            //accountPersistantId: '456763',
            accountPersistentId: '456763',
            accountNumber: '123456789',
            accountName: 'XYZ Beck',
            accountType: 'CHK',
            entityType: 'Checking',
            ulek: '123456',
            eodNtBalance: 30450,
            institution: 'Northern Trust',
            valueAsOfDate: '2023-08-09',
            id: "",
            isEntityCapitalGains: true,
            isLiabilityPaidByPortfolio: true,
            legalEntityOwnerships: [],
            memberOwnerships: [],
            ordinal: 0,
            ownershipCategory: "Sole",
            profileId: "",
            taxStatus: "Taxable",
            doesPermitBeneficiary: null,
            ...bankingAccountOverrides
        }
    ]
}

export function generateBankingAccountSummary(): BankingAccountsSummary{
    return   {
        id: "",
        profileId: "",
        ordinal: 0,
        doesPermitBeneficiary: false,
        ownershipCategory: "Sole",
        memberOwnerships: [],
        legalEntityOwnerships: [],
        accountPersistentId: 456763,
        accountNumber: '123456789',
        accountName: 'XYZ Beck',
        accountType: 'CHK',
        entityType: 'Checking',
        ulek: 123456,
        eodNtBalance: 30450,
        institution: 'test-bank-instt',
        valueAsOfDate: '2023-08-09',
        isSelected: true,
        holdings: generateHeldAwayAccountHoldingsSummary(),
        taxStatus: "Taxable",
        isLiabilityPaidByPortfolio: false,
        isEntityCapitalGains: false
    };

}

export function generateHeldAwayAccount(heldAwayAccountOverrides?: Partial<HeldAwayAccount>): HeldAwayAccount {
    return {
        taxStatus: "Taxable",
        profileId: "",
        id: `some-held-away-account-id-${getRandomNumber()}`,
        financialAccountName: 'XYZ External Account',
        financialAccountNumber: '801-8905',
        financialAccountPersistentId: "123456",
        investmentProgramAssociationId: "1234-1234-2234-6543",
        valueAsOfDate: '2022-01-08',
        doesPermitBeneficiary: false,
        baseMarketValue: 10000,
        investableValue: 10000,
        entityType: 'Taxable Account',
        institution: 'Merrill Lynch',
        ordinal: -1,
        legalEntityOwnerships: [],
        memberOwnerships: [generateMemberOwnership()],
        ownershipCategory: "Sole",
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,
        ...heldAwayAccountOverrides
    }
}

export function generateHeldAwaySummary(heldAwayAccountOverrides?: Partial<HeldAwayAccountSummary>): HeldAwayAccountSummary {
    return {
        taxStatus: "Taxable",
        profileId: "",
        id: `some-held-away-account-id-${getRandomNumber()}`,
        financialAccountName: 'XYZ External Account',
        financialAccountNumber: '801-8905',
        financialAccountPersistentId: "123456",
        investmentProgramAssociationId: "1234-1234-2234-6543",
        valueAsOfDate: '2022-01-08',
        baseMarketValue: 10000,
        doesPermitBeneficiary: false,
        investableValue: 10000,
        entityType: 'Taxable Account',
        institution: 'Merrill Lynch',
        ordinal: -1,
        legalEntityOwnerships: [],
        memberOwnerships: [generateMemberOwnership()],
        ownershipCategory: "Sole",
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,

        marketEstateValue: {
            inEstateValue: 11000,
            outOfEstateValue: 3000,
            totalValue: 14000
        },


        holdings: generateHeldAwayAccountHoldingsSummary(),
        marketValue: {
            inEstateValue: 10000,
            outOfEstateValue: 0,
            totalValue: 10000
        },
        ...heldAwayAccountOverrides,
    }
}

export const generateHeldAwayAccountHoldingsSummary: () => HoldingSummary = () => {
    return {
        totalMarketValue: 10800.54,
        totalInvestableValue: 10800.54,
        totalTaxCost: 1232,
        unrealizedCapitalGainsTax: -1500,
        deferredTaxLiability: -27407,
        asOfDate: '2024-06-16',
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                id: 'id-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: "US Broad Market",
                accountNumber: "12345",
                productId: '123',
                productName: "ELM CLO LTD 144A FLTG SER 14-1A CL DR",
                marketEstateValue: {
                    inEstateValue: 100.1,
                    outOfEstateValue: 0,
                    totalValue: 100.1
                },
                taxCost: 50.25,
                investableValue: 3100.00,
                locked: false,
                concentrated: false,
                ticker: "ELM",
                cusip: "289247AQ1",
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                id: 'id-ex-us-broad-market',
                assetSubclassDetailsId: 'subclass-details-id-developed-ex-us-broad-market',
                assetSubclassDetailsName: "Developed Ex-US Broad Market",
                accountNumber: "456456",
                productId: '998',
                productName: "BUY TO OPEN REPO W/RBC DOMINION SE .2%",
                marketEstateValue: {
                    inEstateValue: 200.1,
                    outOfEstateValue: 0,
                    totalValue: 200.1
                },
                taxCost: 60.25,
                investableValue: 3400.00,
                locked: false,
                concentrated: true,
                type: HoldingType.Product
            }),
            generateHoldingAssetSubclassDetail({
                id: 'tax-exempt-inter-matur-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-tax-exempt-intermediate-maturity-fixed-income",
                assetSubclassDetailsName: "Tax Exempt Intermediate Maturity Fixed Income",
                accountNumber: "45656",
                productId: '457',
                productName: "Tax Exempt Intermediate Maturity Fixed Income",
                taxCost: 3000.3,
                marketEstateValue: {
                    inEstateValue: 5000,
                    outOfEstateValue: 0,
                    totalValue: 5000
                },
                investableValue: 4500,
            }),
            generateHoldingAssetSubclassDetail({
                id: 'other-fixed-income-id',
                assetSubclassDetailsId: "subclass-details-id-other-fixed-income",
                assetSubclassDetailsName: "Some Other Fixed Income",
                accountNumber: "123456",
                productId: '098',
                productName: "Some Other Fixed Income",
                taxCost: 3070.3,
                marketEstateValue: {
                    inEstateValue: 5500,
                    outOfEstateValue: 0,
                    totalValue: 5500
                },
                investableValue: 5500
            })
        ],
    }
};

export function generatePersonalLiabilities(personalLiabilitiesOverrides?: PersonalLiabilitySummary[]): PersonalLiabilitySummary [] {
    return personalLiabilitiesOverrides || [];
}

export function generateAssetsSummary(assetOverrides: Partial<AssetsSummary> = {}): AssetsSummary {
    return {
        accounts: generateAccounts(assetOverrides.accounts),
        personalAssets: generatePersonalAssets(assetOverrides.personalAssets),
        generalInflows: generateGeneralInflows(assetOverrides.generalInflows),
        socialSecurities: generateSocialSecurities(assetOverrides.socialSecurities),
        personalLiabilities: generatePersonalLiabilities(assetOverrides.personalLiabilities),
        investmentProgram: generateInvestmentProgram(assetOverrides.investmentProgram),
        partiallyOwnedLegalAgreements: [generatePartiallyOwnedLegalAgreement()],
        lifeInsurances: generateLifeInsuranceSummary(assetOverrides.lifeInsurances),
        equityCompensations: generateEquityCompensationSummary(assetOverrides.equityCompensations),
        totalPresentValue: 0,
        totalGrossValue: 0,
        bankingAccounts: [],
        totalNetValue: 0,
        totalLiabilitiesValue: 0,
        liabilitiesValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        totalTaxLiabilities: generateTaxLiabilities(assetOverrides.totalTaxLiabilities),
        ...assetOverrides
    };
}

export function generateNetValueSummary(overrides: Partial<NetValueSummary> = {}): NetValueSummary {
    return {
        ...emptyNetValueSummary,
        ...overrides
    }
}

export function generateAssetTotals(assetOverrides: Partial<AssetTotals> = {}): AssetTotals {
    return {
        netValue: emptyNetValueSummary,
        totalGeneralInflowsPresentValue: 0,
        netWorthValueAtDeath: generateNetValueSummary(assetOverrides.netWorthValueAtDeath),
        totalGrossValue: 0,
        totalInvestableValue: 0,
        totalInvestableValueForRiskControlAssets: 0,
        totalLiabilitiesValue: 0,
        totalNetValue: 0,
        totalPersonalAssetsPresentValue: 0,
        totalSocialSecurityPresentValue: 0,
        totalTaxLiabilities: generateTaxLiabilities(assetOverrides.totalTaxLiabilities),
        ...assetOverrides
    }
}

/* Redux type generators */

export function generateClientAssetsState(overrides: Partial<ClientAssetsState> = {}): ClientAssetsState {
    return {
        accounts: generateAccounts(overrides?.accounts),
        personalAssets: generatePersonalAssets(overrides?.personalAssets),
        generalInflows: generateGeneralInflows(overrides?.generalInflows),
        socialSecurities: generateSocialSecurities(overrides?.socialSecurities),
        personalLiabilities: generatePersonalLiabilities(overrides?.personalLiabilities),
        partiallyOwnedLegalAgreements: [generatePartiallyOwnedLegalAgreement()],
        investmentProgram: generateInvestmentProgram(overrides?.investmentProgram),
        lifeInsurances: generateLifeInsuranceSummary(overrides.lifeInsurances),
        equityCompensations: generateEquityCompensationSummary(overrides.equityCompensations),
        totalPresentValue: 0,
        totalGrossValue: 0,
        totalNetValue: 0,
        bankingAccounts: [],
        totalLiabilitiesValue: 0,
        liabilitiesValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        activeFormAsset: null,
        totalTaxLiabilities: generateTaxLiabilities(overrides.totalTaxLiabilities),
        ...overrides
    }
}

export function generateLargeClientAssetsState(overrides: Partial<ClientAssetsState> = {}): ClientAssetsState {
    return {
        accounts: generateAccounts(overrides?.accounts),
        personalAssets: generatePersonalAssets(overrides?.personalAssets),
        bankingAccounts: [],
        generalInflows: generateGeneralInflows(overrides?.generalInflows),
        socialSecurities: generateSocialSecurities(overrides?.socialSecurities),
        personalLiabilities: generatePersonalLiabilities(overrides?.personalLiabilities),
        partiallyOwnedLegalAgreements: [generateLargePartiallyOwnedLegalAgreement()],
        investmentProgram: generateInvestmentProgram(overrides?.investmentProgram),
        lifeInsurances: generateLifeInsuranceSummary(overrides.lifeInsurances),
        equityCompensations: generateEquityCompensationSummary(overrides.equityCompensations),
        totalPresentValue: 0,
        totalGrossValue: 0,
        totalNetValue: 0,
        totalLiabilitiesValue: 0,
        liabilitiesValue: {
            inEstateValue: 0,
            outOfEstateValue: 0,
            totalValue: 0
        },
        activeFormAsset: null,
        totalTaxLiabilities: generateTaxLiabilities(overrides.totalTaxLiabilities),
        ...overrides
    }
}

export function generateAppState(assetsOverrides: Partial<ClientAssetsState> = {}) {
    return {
        client: {
            assets: {
                ...generateClientAssetsState(),
                ...assetsOverrides,
            }
        }
    }
}

function getRandomNumber(min = 0, max = 10000) {
    return Math.random() * (max - min) + min;
}

export function generateInvestorGroupMember(overrides?: Partial<InvestorGroupMember>): InvestorGroupMember {
    return {
        id: `some-investor-group-member-id-${getRandomNumber()}`,
        firstName: "Shauna",
        name: "Shauna Nelson",
        birthdate: "1976-11-23",
        planningPeriod: generatePlanningPeriod({
            numberOfYears: 45,
            memberType: InvestorGroupMemberType.PRIMARY,
        }),
        ...overrides
    };
}

export function generateInvestorGroup(overrides?: Partial<InvestorGroupType>): InvestorGroupType {
    return {
        primaryMember: generateInvestorGroupMember(overrides?.primaryMember),
        partnerMember: generateInvestorGroupMember(overrides?.partnerMember),
        planningPeriod: generatePlanningPeriod(),
        ...overrides
    }
}

export function generateMemberGroupMember(overrides?: Partial<MemberGroupMember>): MemberGroupMember {
    return {
        id: `some-member-group-member-id-${getRandomNumber()}`,
        firstName: "Shauna",
        lastName: "Nelson",
        age: 54,
        relationshipType: null,
        lifeStatus: LifeStatus.Living,
        deceasedDate: null,
        ...overrides
    };
}

export function generateMemberGroup(overrides?: Partial<MemberGroup>): MemberGroup {
    return {
        primaryMember: generateMemberGroupMember(overrides?.primaryMember),
        partnerMember: generateMemberGroupMember(overrides?.partnerMember ?? {}),
        additionalMembers: overrides?.additionalMembers ?? [],
        ...overrides
    }
}

export function generateTaxLiabilities(overrides?: Partial<TaxLiabilitySummary>): TaxLiabilitySummary {
    return {
        totalPresentTaxLiabilityForAllAccounts: -100000,
        totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: -100000,
        totalPresentTaxLiabilityForDeferredAccounts: -25000,
        totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: -25000,
        totalPresentTaxLiabilityForTaxableAccounts: -100000,
        totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: -100000,
        ...overrides
    }
}

export function generatePlanningPeriod(overrides?: Partial<PlanningPeriodType>): PlanningPeriodType {
    return {
        numberOfYears: 45,
        memberType: InvestorGroupMemberType.PRIMARY,
        ageFrom: 50,
        startYear: 2021,
        ...overrides
    };

}

export function generateMemberOwnershipWriteModel(overrides?: Partial<MemberOwnershipWriteModel>): MemberOwnershipWriteModel {
    return {
        memberId: "owner-member-id",
        percentage: 100,
        isRevocableTrust: false,
        ...overrides
    };
}

export function generateMemberOwnership(overrides?: Partial<MemberOwnership>): MemberOwnership {
    return {
        member: generateMember(overrides?.member),
        percentage: 100,
        isRevocableTrust: false,
        isInEstateMember: true,
        ...overrides
    };
}

export function generateMember(overrides?: Partial<Member>): Member {
    return {
        id: "owner-member-id",
        name: "owner-member-name",
        ...overrides
    }
}

export function generateMemberOwnershipFormData(overrides?: Partial<MemberOwnershipFormData>): MemberOwnershipFormData {
    return {
        memberId: "owner-member-id",
        percentage: "100.00",
        isRevocableTrust: false,
        ...overrides
    }
}

export function generateNonClientMemberOwnershipFormData(overrides?: Partial<NonClientMemberOwnershipFormData>): NonClientMemberOwnershipFormData {
    return {
        memberId: "test-non-client-member-id",
        percentage: "0",
        isRevocableTrust: false,
        ...overrides
    }
}

export function generateLegalEntityFormData(overrides?: Partial<LegalEntityFormData>): LegalEntityFormData {
    return {
        type: undefined,
        name: "",
        ...overrides
    };
}

export function generateLegalEntityReadModel(overrides?: Partial<LegalEntityReadModel>): LegalEntityReadModel {
    return {
        id: "",
        type: "Other",
        name: "",
        ...overrides
    };
}

export function generateLegalEntityOwnership(overrides?: Partial<LegalEntityOwnership>): LegalEntityOwnership {
    return {
        legalEntity: generateLegalEntityReadModel({
            id: undefined,
            name: "",
            type: undefined,
        }),
        percentage: 0,
        showWithinOutOfEstateReport: false,
        ...overrides
    };
}

export function generateLegalEntityOwnershipWriteModel(overrides?: Partial<LegalEntityOwnershipWriteModel>): LegalEntityOwnershipWriteModel {
    return {
        legalEntity: {
            name: "",
            type: "Other",
        },
        percentage: 0,
        showWithinOutOfEstateReport: false,
        ...overrides
    };
}

export function generateLegalEntityOwnershipFormData(overrides?: Partial<LegalEntityOwnershipFormData>): LegalEntityOwnershipFormData {
    return {
        type: undefined,
        name: "",
        percentage: "0",
        showWithinOutOfEstateReport: true,
        legalEntityId: undefined,
        ...overrides
    }
}

export function generateSoleLegalEntityOwnershipFormData(overrides?: Partial<LegalEntityOwnershipFormData>): LegalEntityOwnershipFormData {
    return {
        type: undefined,
        name: "",
        percentage: "0",
        showWithinOutOfEstateReport: false,
        legalEntityId: undefined,
        ...overrides
    }
}

export function generateOwnershipDetailsFormData(overrides?: Partial<OwnershipDetailsFormData>): OwnershipDetailsFormData {
    return {
        ownershipCategory: "Sole",
        soleOwnerType: "Individual",
        memberOwnerships: [generateMemberOwnershipFormData({
            memberId: "shauna-id",
            percentage: "100.00",
            isRevocableTrust: false,
        })],
        legalEntityOwnerships: [],
        nonClientMemberOwnerships: [],
        ...overrides
    }
}

export function generateSocialSecurityBenefits(overrides?: Partial<SocialSecurityBenefits>): SocialSecurityBenefits {
    return {
        startAge: 70,
        grossAnnualFlow: 54293.40,
        netAnnualFlow: 35000,
        yearsOfFlow: 30,
        yearsUntilFlow: 20,
        presentValue: 900000,
        taxRate: 20,
        interestRate: 0.0067,
        lifestyleGoalAligned: 500000,
        excessFutureInflow: 400000,
        isInflowWillFundLifestyleGoal: true,
        inflowReserveLength: null,
        inflowReservePresentValue: 1000,
        ...overrides
    }
}

export function generateInEstateAssetTotals(overrides?: Partial<InEstateAssetTotals>): InEstateAssetTotals {
    return {
        accountTotal: 0,
        futureInflowsTotal: 0,
        personalAssetTotal: 0,
        lifeInsuranceTotal: 0,
        equityCompensationTotal: 0,
        personalLiabilitiesTotal: 0,
        totalGrossValue: 0,
        totalNetValue: 0,
        ...overrides
    }
}

export function generateActiveFormAsset(overrides?: Partial<ActiveFormAsset>): ActiveFormAsset {
    return {
        id: undefined,
        assetType: 'personalAsset',
        inEstateValue: 1,
        description: '',
        hasInEstateOwnership: true,
        ...overrides
    };
}

export function generateLifeInsuranceBeneficiaries(memberId: string) {
    return {
        id: "",
        memberId: memberId,
        entityName: null,
        percentage: "100.00"
    }
}

export function generateInEstateAssetsTotals() {
    let inEstateAssetTotals: InEstateAssetTotals = {
        accountTotal: 0,
        personalAssetTotal: 0,
        futureInflowsTotal: 0.0,
        lifeInsuranceTotal: 0,
        equityCompensationTotal: 0,
        personalLiabilitiesTotal: 0,
        totalNetValue: 0,
        totalGrossValue: 0
    }
    return inEstateAssetTotals;
}

export function generateEquityCompensation(overrides?: Partial<EquityCompensationFormData>): EquityCompensationFormData {
    return {
        id: '71fbc1f1-0289-4134-98ef-bec700587073',
        profileId: 'abc-123',
        entityOrAccountName: 'Test account',
        ticker: 'NT',
        asOfDate: '2023-06-16',
        companyOrStockName: 'NT',
        stockPrice: "72.32",
        taxRate: "37.00",
        owner: 'shauna-id',
        doesPermitBeneficiary: false,
        preTaxUnvestedValue: null,
        preTaxVestedValue: null,
        afterTaxUnvestedValue: null,
        afterTaxVestedValue: null,
        stockOptionPreTaxUnvestedValue: null,
        stockOptionPreTaxVestedValue: null,
        stockOptionAfterTaxUnvestedValue: null,
        stockOptionAfterTaxVestedValue: null,
        stockGrantPreTaxUnvestedValue: null,
        stockGrantAfterTaxUnvestedValue: null,
        stockOptionsAsOfDate: null,
        stockGrantsAsOfDate: null,
        ...overrides
    }
}

export function generateEquityCompensationWithTotals(overrides?: Partial<EquityCompensationFormData>): EquityCompensationFormData {
    return {
        id: '71fbc1f1-0289-4134-98ef-bec700587073',
        profileId: 'abc-123',
        entityOrAccountName: 'Test account',
        ticker: 'NT',
        asOfDate: '2023-06-16',
        companyOrStockName: 'NT',
        stockPrice: "72.32",
        taxRate: "37.00",
        owner: 'shauna-id',
        doesPermitBeneficiary: false,
        preTaxUnvestedValue: 400_000,
        preTaxVestedValue: 100_000,
        afterTaxUnvestedValue: 320_000,
        afterTaxVestedValue: 80_000,
        stockOptionPreTaxUnvestedValue: 300_000,
        stockOptionPreTaxVestedValue: 100_000,
        stockOptionAfterTaxUnvestedValue: 240_000,
        stockOptionAfterTaxVestedValue: 80_000,
        stockGrantPreTaxUnvestedValue: 100_000,
        stockGrantAfterTaxUnvestedValue: 80_000,
        stockOptionsAsOfDate: '2023-06-16',
        stockGrantsAsOfDate: '2023-06-16',
        ...overrides
    }
}

export function generateStockOptionsAndGrantsResponse(overrides?: Partial<StockOptionsAndGrantsResponse>): StockOptionsAndGrantsResponse {
    return {
        entityOrAccountName: 'Test account',
        asOfDate: '2023-06-16',
        stockPrice: 2072.32,
        effectiveTaxRate: 37.00,
        capitalGainsTaxRate: 20.00,
        stockOptions: [],
        stockGrants: [],
        ...overrides
    }
}

export function generateStockOptionFormData(overrides?: Partial<StockOption>): StockOption {
    return {
        id: 'stock-option-1',
        grantDate: '2023-06-16',
        expirationDate: '2028-06-16',
        type: StockOptionType.ISO,
        grantDescription: "2023 Stock Option",
        exercisePrice: "150.00",
        sharesGranted: 1000,
        sharesExercised: 200,
        sharesVested: 200,
        sharesUnvested: 800,
        grossPotentialValue: 20000,
        grossExercisableValue: 15000,
        afterTaxPotentialValue: 16000,
        afterTaxUnvestedMarketValue: 12000,
        afterTaxExercisableValue: 4000,
        vested: true,
        createdAt: null,
        updatedAt: null,
        ...overrides
    }
}

export function generateCalculateStockOptionRequest(overrides?: Partial<CalculateStockOptionRequest>): CalculateStockOptionRequest {
    return {
        id: 'stock-option-1',
        grantDate: '2023-06-16',
        expirationDate: '2028-06-16',
        type: StockOptionType.ISO,
        grantDescription: "2023 Stock Option",
        exercisePrice: "150.00",
        sharesGranted: 1000,
        sharesExercised: 200,
        sharesVested: 200,
        vested: true,
        stockPrice: 120,
        effectiveTaxRate: 35,
        capitalGainsTaxRate: 20,
        createdAt: null,
        updatedAt: null,
        ...overrides
    }
}

export function generateStockOptionWriteModel(overrides?: Partial<StockOptionWriteModel>): StockOptionWriteModel {
    return {
        id: 'stock-option-1',
        grantDate: '2023-06-16',
        expirationDate: '2028-06-16',
        type: StockOptionType.NQSO,
        grantDescription: "2023 Stock Option",
        exercisePrice: "150.00",
        sharesGranted: 1000,
        sharesExercised: 200,
        createdAt: null,
        updatedAt: null,
        ...overrides
    }
}

export function generateVestingScheduleResponse(overrides?: Partial<VestingScheduleResponse>): VestingScheduleResponse {
    return {
        id: 'stock-option-1',
        grantDate: '2023-06-16',
        expirationDate: '2028-06-16',
        type: StockOptionType.NQSO,
        grantDescription: "2023 Stock Option",
        sharesGranted: 1000,
        grossPotentialValue: 34000,
        afterTaxPotentialValue: 26000,
        grossExercisableValue: 34000,
        afterTaxExercisableValue: 26000,
        afterTaxUnvestedMarketValue: 0,
        grossUnvestedValue: 0,
        vestingPeriods: [
            generateVestingPeriod({
                id: 'vesting-period-1'
            }),
            generateVestingPeriod({
                id: 'vesting-period-2',
                nextVestingDate: '2024-06-16'
            }),
            generateVestingPeriod({
                id: 'vesting-period-3',
                nextVestingDate: '2025-06-16'
            })
        ],
        ...overrides
    }
}

export function generateVestingPeriod(overrides?: Partial<VestingPeriod>): VestingPeriod {
    return {
        id: 'vesting-period-1',
        nextVestingDate: '2023-06-16',
        percentage: 10,
        sharesVestingPerPeriod: 100,
        isVested: false,
        ...overrides
    }
}

export function generateVestingPeriodWriteModel(overrides?: Partial<VestingPeriodWriteModel>): VestingPeriodWriteModel {
    return {
        nextVestingDate: '2023-06-16',
        percentage: 10,
        sharesVestingPerPeriod: 100,
        ...overrides
    }
}

export function generateStockGrantFormData(overrides?: Partial<StockGrant>): StockGrant {
    return {
        id: 'stock-grant-1',
        type: StockGrantType.PSU,
        grantDate: '2023-06-20',
        expirationDate: '2028-06-20',
        grantDescription: "2023 Stock Grant",
        sharesGranted: 1000,
        sharesVested: 200,
        sharesUnvested: 800,
        grossUnvestedValue: 57856,
        afterTaxUnvestedMarketValue: 36449,
        vested: true,
        createdAt: null,
        updatedAt: null,
        ...overrides
    }
}

export function generateAutoVestingPeriods(overrides?: Partial<AutoCalculateVestingPeriodResponse>): AutoCalculateVestingPeriodResponse {
    return {
        sharesGranted: 1000,
        frequency: 'Annual',
        grantDate: '2023-06-16',
        expirationDate: '2028-06-16',
        vestingPeriods: [
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2023-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            }),
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2024-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            }),
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2025-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            }),
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2026-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            }),
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2027-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            }),
            generateVestingPeriod({
                id: generate_uuid(),
                nextVestingDate: '2028-06-16',
                percentage: 16.66,
                sharesVestingPerPeriod: 166.66
            })
        ],
        ...overrides
    }
}

export function generateVestingInfoForStockGrant(overrides?: Partial<CalculateVestingInfoResponse>): CalculateVestingInfoResponse {
    return {
        sharesGranted: 1000,
        grossPotentialValue: null,
        afterTaxPotentialValue: null,
        grossExercisableValue: null,
        afterTaxExercisableValue: null,
        grossUnvestedValue: 50624,
        afterTaxUnvestedMarketValue: 31893,
        ...overrides
    }
}

function createPartiallyOwnedLegalAggrement<T>(element:T, count:number):T[]{
    return Array(count).fill(element);
}
let multipleAccounts = createPartiallyOwnedLegalAggrement(generateLargePartiallyOwnedLegalAgreement(),1000);

export function generateHoldingsForHeldAwayAccount() : HeldAwayAccountHoldingsRequest {
    return {
        asOfDate: "2024-05-30",
        allHoldings: [
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'subclass-details-id-us-broad-market',
                assetSubclassDetailsName: 'US Broad Market',
            }),
            generateHoldingAssetSubclassDetail({
                assetSubclassDetailsId: 'subclass-details-id-tax-exempt-intermediate-maturity-fixed-income',
                assetSubclassDetailsName: 'Tax Exempt Intermediate Maturity Fixed Income',
            })
        ],
    }
}