import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import deepEquals from "fast-deep-equal";
import {BankingAccountForm} from "./BankingAccountForm";
import {BankingAccountFormData, BankingAccountsSummary} from "../../models/BankingAccounts";
import AssetsViewContext from "../../common/AssetsViewContext";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {TaxDetailsType} from "../../models/TaxDetails";
import {RouteWithAssetId} from "../../../routes/types";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../../models/Ownership";
import {assetsApiClient} from "../../AssetsApiClient";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {
    extractOwnershipDetailsFormData,
    mapToOwnershipDetailsFormData,
    mapToOwnershipWriteModel
} from "../../Ownership/mappers";
import {setActiveFormAsset} from "../../clientAssetsSlice";
import {hasSomeInEstateOwnership} from "../../Ownership/helpers";
import {isOwnershipPercentageNotEqual100} from "../../Ownership/validation";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {HistoryBlockModal} from "../../../components/HistoryBlockModal/HistoryBlockModal";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {RequiredFieldsBanner} from "../../../components";
import {AccountSummary} from "../../AccountsCommon/AccountSummary";
import DiscardAssetModal from "../../DiscardAssetModal";
import {HoldingSummary} from "../../models/Holding";
import {AssetClassifications} from "../../models/AssetClassifications";
import {generateHoldingsSummary} from "../../__testUtils__/generators";

export default function EditBankingAccount() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const [bankingAccount, setBankingAccount] = useState<BankingAccountsSummary>();
    const [initialBankingAccount, setInitialBankingAccount] = useState<BankingAccountsSummary>();

    const [taxDetails, setTaxDetails] = useState<TaxDetailsType>({
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false
    });
    const [initialTaxDetails, setInitialTaxDetails] = useState<TaxDetailsType>();
    const bankingAccountHoldings : HoldingSummary = generateHoldingsSummary();
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] = useState<number | null>(null);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState<number | null>(null);

    const {assetId: bankingAccountId} = useParams<RouteWithAssetId>();
    const totalInvestablePresentValue = 0;
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [initialOwnershipDetailsFormData, setInitialOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const dispatch = useAppDispatch();
    const mounted = useRef(false);

    useEffect(() => {
        Promise.all([
            assetsApiClient.getBankingAccountForAssetPersistentId(profile.id,bankingAccountId ),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ]).then(([
                     bankingAccountResponse,
                     memberGroupResponse,
                     legalEntitiesResponse]) => {
            setTaxDetails({
                isEntityCapitalGains: bankingAccountResponse.isEntityCapitalGains!,
                isLiabilityPaidByPortfolio: bankingAccountResponse.isLiabilityPaidByPortfolio!,
            });

            setInitialTaxDetails({
                isEntityCapitalGains: bankingAccountResponse.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: bankingAccountResponse.isLiabilityPaidByPortfolio,
            });
            setBankingAccount(bankingAccountResponse);
            setInitialBankingAccount(bankingAccountResponse);
            updateOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(bankingAccountResponse)));
            setInitialOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(bankingAccountResponse)));
            setMemberGroup(memberGroupResponse);
            updateLegalEntities(legalEntitiesResponse);

            dispatch(setActiveFormAsset({
                assetType: 'bankingAccount',
                id: bankingAccountResponse.id || undefined,
                inEstateValue: 0,
                description: bankingAccountResponse.accountName,
                hasInEstateOwnership: hasSomeInEstateOwnership(bankingAccountResponse.memberOwnerships),
            }));
        }).catch(error => console.error('Could not fetch holding data', error.message));
    }, [profile.id, bankingAccountId]);

    useEffect(() => {
        mounted.current = true;
        if (bankingAccount?.taxStatus === "Deferred") {
            const updatedTaxDetails: TaxDetailsType = {
                isEntityCapitalGains: null,
                isLiabilityPaidByPortfolio: bankingAccount.isLiabilityPaidByPortfolio
            };
            setTaxDetails(updatedTaxDetails);
            handleDeferredTaxLiabilityChange()
                .catch(reason => console.log(reason));
            updateUnrealizedCapitalGainsTax(null);
        } else if (bankingAccount?.taxStatus === "Taxable" && taxDetails?.isEntityCapitalGains !== null) {

            updateDeferredTaxLiability(null);
        }

        if((bankingAccount?.taxStatus === "Deferred" || bankingAccount?.taxStatus === "Taxable") && taxDetails?.isLiabilityPaidByPortfolio) {
            getInvestablePresentValue().then();
        }
        return () => {
            mounted.current = false;
        }
    }, [bankingAccount?.taxStatus]);


    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [taxDetails, bankingAccount?.accountName, ownershipDetailsFormData?.legalEntityOwnerships]);

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown &&
            isOwnershipPercentageNotEqual100(ownershipDetailsFormData!));
    }, [ownershipDetailsFormData?.legalEntityOwnerships, ownershipDetailsFormData?.memberOwnerships]);

    useEffect(() => {
        assetsApiClient.getAssetClassifications().then(assetClassificationResponse => setClassifications(assetClassificationResponse));
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            dispatch(setActiveFormAsset(null));
        }
    }, []);

    const handleDeferredTaxLiabilityChange = async () =>
    {
        return false;
    }

    const isAnyRequiredFieldEmpty = () => {
        const isNameBlank = !bankingAccount?.accountName.trim();
        const isLiabilityQuestionVisibleForTaxable = bankingAccount!.taxStatus === "Taxable" && !!taxDetails!.isEntityCapitalGains;
        const isLiabilityQuestionVisibleForDeferred = bankingAccount!.taxStatus === "Deferred";
        const isLiabilityQuestionVisible = isLiabilityQuestionVisibleForTaxable || isLiabilityQuestionVisibleForDeferred;
        const isLiabilityQuestionBlank = taxDetails!.isLiabilityPaidByPortfolio === null;
        const isOwnershipDataMissing = ownershipDetailsFormData?.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });
        return isNameBlank || isOwnershipDataMissing || (isLiabilityQuestionBlank && isLiabilityQuestionVisible);
    }
    const onOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        updateOwnershipDetailsFormData(ownershipFormData);
    }
    const handleSave = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            updateSaveButtonDisabled(true);
            setShowNavigationModal(false);
            const response = await assetsApiClient.updateBankAccount(profile.id, bankingAccountId, {

                ...mapToBankingAccountModel(bankingAccount!),

                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!),
                doesPermitBeneficiary: bankingAccount?.doesPermitBeneficiary ?? false,
            });
            if (response.status === 200) {
                navigateToAssetsView();
            }
            return true;
        }
        return false;
    }
    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const isFormChanged = () => {
        const updated = {
            ...bankingAccount,
            ...taxDetails,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...initialBankingAccount,
            ...initialTaxDetails,
            ...initialOwnershipDetailsFormData
        }
        return !deepEquals(initial, updated);
    }
    const handleCancel = () => {
        if (isFormChanged()) {
            setShowDiscardModal(true);
            setShowNavigationModal(false);
            return;
        }
        navigateToAssetsView();
    }
    const handleUnrealizedCapitalGainsTaxChange = async () =>
    {
        return false;
    }
    const handleClickViewHoldings = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            setShowNavigationModal(false);
            const response = await assetsApiClient.updateBankAccount(profile.id, bankingAccountId, {

                ...mapToBankingAccountModel(bankingAccount!),

                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!)
            });
            if (response.status === 200) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/HeldAwayAccountHoldings/${bankingAccountId}`);
            }
        }
    };
    const validateForm = () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }
        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100(ownershipDetailsFormData!);
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }
        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };
    const getInvestablePresentValue = async () =>
    {
        return false
    }

    if (!(
        bankingAccountId
        && ownershipDetailsFormData
        && classifications
        && memberGroup
        && legalEntities)) {
        return <LoadingIndicator/>;
    }
    return (
        <div className="held-away-account asset-form">
            <div className="layout-data-entry-form">
                <HistoryBlockModal
                    when={isFormChanged() && showNavigationModal}
                    itemType={'page'}
                    onSave={handleSave}
                />
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={`Edit ${bankingAccount?.accountName}`}
                    onPrimaryButtonClick={handleSave}
                    onSecondaryButtonClick={handleCancel}
                    disablePrimaryButton={isSaveButtonDisabled}
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                />
                <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="held away account"/>
                <div className="held-away-account__form layout-data-entry-form">
                    <BankingAccountForm
                        ownershipDetailsFormData={ownershipDetailsFormData}
                        isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                        isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                        handleBankingAccountChange={setBankingAccount}
                        taxDetails={taxDetails}
                        handleTaxDetailsChange={setTaxDetails}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        totalInvestablePresentValue={totalInvestablePresentValue}
                        getInvestablePresentValue={getInvestablePresentValue}
                        handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                        memberGroup={memberGroup}
                        legalEntities={legalEntities}
                        handleLegalEntitiesChange={updateLegalEntities}
                        onOwnershipFormChange={onOwnershipFormChange}
                        bankingAccount={bankingAccount!}
                        asOfDate={""}
                        bankingAccountHoldings={bankingAccountHoldings}
                    />
                    <AccountSummary
                        assetType={'bankingAccount'}
                        holdings={bankingAccountHoldings}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        onClick={handleClickViewHoldings}
                        classifications={classifications}
                    />
                </div>
            </div>
            <DiscardAssetModal
                isOpen={showDiscardModal}
                title={`Discard changes to this Asset?`}
                content={`Any data entered for this asset will not be saved.`}
                onClickKeepEditing={() => setShowDiscardModal(false)}
                onClickDiscardChanges={navigateToAssetsView}
            />
        </div>
    );
}



function mapToFormData(bankingAccount: BankingAccountsSummary): BankingAccountFormData {
    return {
        ...bankingAccount,
        ...mapToOwnershipDetailsFormData(bankingAccount),
    };
}

function mapToBankingAccountModel(bankAccount: BankingAccountsSummary) {
    const {ownershipCategory, memberOwnerships, legalEntityOwnerships, ...bankingAccount} = bankAccount;

    return bankingAccount;
}
