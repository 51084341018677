import React, {ChangeEvent, useEffect, useState} from "react";
import moment from "moment";
import {BankingAccountsSummary} from "../../models/BankingAccounts";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../../models/Ownership";
import {TaxDetailsType, TaxStatusOptions} from "../../models/TaxDetails";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {HoldingSummary} from "../../models/Holding";
import {useAppDispatch} from "../../../store/hooks";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import {setActiveFormAsset} from "../../clientAssetsSlice";
import {calculateInEstateFormValue} from "../../AssetSummary/common/AssetSummaryCalculator";
import {hasSomeInEstateOwnershipInForm} from "../../Ownership/helpers";
import {mapToOwnershipWriteModel} from "../../Ownership/mappers";
import {booleanToRadioButton, radioButtonToBoolean} from "../../formHelpers";
import {RadioYesNoOptional} from "../../models/Common";
import TaxDetails from "../../TaxDetails/TaxDetails";
import {
    Dropdown,
    DropdownItem,
    Input, RadioGroup,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../../components";
import Ownership from "src/Assets/Ownership";

type BankingAccountFormLikeHAAFormProps = {
    bankingAccount: BankingAccountsSummary,
    ownershipDetailsFormData: OwnershipDetailsFormData,
    isRequiredFieldsBannerShown: boolean,
    isOwnershipPercentageErrorBannerShown: boolean,
    handleBankingAccountChange: Function,
    taxDetails: TaxDetailsType,
    handleTaxDetailsChange: (taxDetails: TaxDetailsType) => void,
    unrealizedCapitalGainsTax: number | null,
    deferredTaxLiability: number | null,
    totalInvestablePresentValue?: number | null,
    getInvestablePresentValue: (liabilityPaidByPortfolio: boolean) => void
    handleUnrealizedCapitalGainsTaxChange: (isEntityCapitalGains: boolean) => void,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    handleLegalEntitiesChange: (legalEntities: LegalEntityFormData[]) => void,
    onOwnershipFormChange: (ownershipFormData: OwnershipDetailsFormData) => void
    asOfDate: string | undefined,
    bankingAccountHoldings?: HoldingSummary
}
const defaultIsEntityCapitalGains = true;
const taxStatusOptions = Object.freeze(["Taxable", "Deferred", "Exempt"]);
const taxStatusDropdownItems = taxStatusOptions.map(taxOption => (<DropdownItem key={taxOption}
                                                                                itemText={taxOption}
                                                                                value={taxOption}/>));
export const BankingAccountForm = ({
                                        bankingAccount,
                                        ownershipDetailsFormData,
                                        isRequiredFieldsBannerShown,
                                        isOwnershipPercentageErrorBannerShown,
                                        handleBankingAccountChange,
                                        taxDetails,
                                        handleTaxDetailsChange,
                                        unrealizedCapitalGainsTax,
                                        deferredTaxLiability,
                                        totalInvestablePresentValue,
                                        getInvestablePresentValue,
                                        handleUnrealizedCapitalGainsTaxChange,
                                        memberGroup,
                                        legalEntities,
                                        handleLegalEntitiesChange,
                                        onOwnershipFormChange,
                                        asOfDate,
                                        bankingAccountHoldings
                                    }: BankingAccountFormLikeHAAFormProps) => {
    const [isNameEmptyOnBlur, setIsNameEmptyOnBlur] = useState(false);
    const dispatch = useAppDispatch();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        const totalValue = bankingAccountHoldings?.totalMarketValue ?? 0;

        dispatch(setActiveFormAsset({
            assetType: 'bankingAccount',
            id: bankingAccount.id,
            inEstateValue: calculateInEstateFormValue(totalValue, ownershipDetailsFormData.memberOwnerships),
            description: bankingAccount.accountName,
            hasInEstateOwnership: hasSomeInEstateOwnershipInForm(mapToOwnershipWriteModel(ownershipDetailsFormData).memberOwnerships),
        }));
        return () => {
            dispatch(setActiveFormAsset(null));
        };
    }, [bankingAccount.accountName, ownershipDetailsFormData]);


    const isNameInlineErrorShown = () => {
        const isNameEmptyOnSave = isRequiredFieldsBannerShown && !bankingAccount?.accountName.trim();
        return isNameEmptyOnBlur || isNameEmptyOnSave;
    };
    const handleOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        onOwnershipFormChange(ownershipFormData);
    }
    const resetAccountAndTaxDetailsToDefaults = (selectedTaxStatus: TaxStatusOptions) => {
        const updatedAccountDetails: BankingAccountsSummary = {
            ...bankingAccount,
            taxStatus: selectedTaxStatus,
        };
        const updatedTaxDetails: TaxDetailsType = {
            isEntityCapitalGains: null,
            isLiabilityPaidByPortfolio: null,
        };
        switch (selectedTaxStatus) {
            case "Taxable":
                updatedTaxDetails.isEntityCapitalGains = defaultIsEntityCapitalGains;
                updatedTaxDetails.isLiabilityPaidByPortfolio = bankingAccount.isLiabilityPaidByPortfolio;
                break;
            case "Deferred":
                updatedTaxDetails.isLiabilityPaidByPortfolio = bankingAccount.isLiabilityPaidByPortfolio;
                break;
            case "Exempt":
                break;
            default:
                break;
        }
        return {updatedAccountDetails, updatedTaxDetails};
    };
    const handleTaxStatusChange = (selectedTaxStatus: TaxStatusOptions) => {
        const {updatedAccountDetails, updatedTaxDetails} = resetAccountAndTaxDetailsToDefaults(selectedTaxStatus);
        handleTaxDetailsChange(updatedTaxDetails);
        handleBankingAccountChange(updatedAccountDetails);
    }

    const totalAssetValue = bankingAccountHoldings ? bankingAccountHoldings.totalMarketValue : null;

    return (
        <article>
            <section className="held-away-section">
                <UnderlinedHeader primaryText="Account Details" secondaryContent={<RequiredFieldsSubheader/>}/>
                <div className="layout-data-entry-form__field">
                    <label id="accountName-label"> <b>Name<RedAsterisk/></b>
                    </label>
                    <Input
                        aria-label="accountName"
                        aria-labelledby="accountName-label"
                        value={bankingAccount.accountName}
                        error={isNameInlineErrorShown() ? "Name is required." : undefined}
                        onBlur={() => setIsNameEmptyOnBlur(!bankingAccount.accountName.trim())}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleBankingAccountChange({
                                ...bankingAccount,
                                accountName: e.target.value
                            })
                        }}
                        readOnly={isProfileWithProposalsOrArchived}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="asOfDate-label">
                        <b>As of Date</b>
                    </label>
                    <span role="note" aria-labelledby="asOfDate-label" className="paddingleft-md">
                        {asOfDate ? `${moment(asOfDate).format("MM/DD/YYYY")}` : "--"}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="accountNumber-label">
                        <b>Account Number</b>
                    </label>
                    <span role="note" aria-labelledby="accountNumber-label" className="paddingleft-md">
                        {bankingAccount.accountNumber}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="entityType-label">
                        <b>Entity Type</b>
                    </label>
                    <span role="note" aria-labelledby="entityType-label" className="paddingleft-md">
                        {bankingAccount.entityType ? bankingAccount.entityType : '––'}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="institution-label">
                        <b>Institution</b>
                    </label>
                    <span role="note" aria-labelledby="institution-label" className="paddingleft-md">
                        {bankingAccount.institution}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="taxStatus">
                        <b>Tax Status</b>
                    </label>
                    <Dropdown
                        className="taxStatusField"
                        name="taxStatus"
                        id="taxStatusInput"
                        aria-labelledby="taxStatus"
                        size="medium"
                        onChange={(data: any) => handleTaxStatusChange(data?.value as TaxStatusOptions)}
                        value={bankingAccount.taxStatus}
                    >
                        {taxStatusDropdownItems}
                    </Dropdown>
                </div>
                {(bankingAccount.taxStatus === "Deferred" || bankingAccount.taxStatus === "Exempt") &&

                    <RadioGroup
                        id="doesPermitBeneficiary"
                        name="doesPermitBeneficiary"
                        label="Does this account permit a beneficiary?"
                        layout="horizontal"
                        values={["Yes", "No"]}
                        disabled={isProfileWithProposalsOrArchived}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {value} = e.target;
                            handleBankingAccountChange({
                                ...bankingAccount,
                                doesPermitBeneficiary: radioButtonToBoolean(value as RadioYesNoOptional),
                            })
                        }}
                        selected={booleanToRadioButton(bankingAccount.doesPermitBeneficiary) ?? 'No'}
                    />
                }
            </section>
            {(bankingAccount.taxStatus === "Taxable" || bankingAccount.taxStatus === "Deferred") &&
                <TaxDetails taxDetails={taxDetails}
                            updateTaxDetails={handleTaxDetailsChange}
                            taxStatus={bankingAccount.taxStatus}
                            isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                            unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                            deferredTaxLiability={deferredTaxLiability}
                            totalInvestablePresentValue={totalInvestablePresentValue}
                            handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                            handleLiabilityPaidByPortfolioChange={getInvestablePresentValue}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
            }
            <Ownership
                onFormDataChange={handleOwnershipFormChange}
                formData={ownershipDetailsFormData}
                totalAssetValue={totalAssetValue}
                isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                memberGroup={memberGroup}
                legalEntities={legalEntities}
                updateLegalEntities={handleLegalEntitiesChange}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
        </article>
    )
}