import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import classNames from "classnames";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {BankingAccountsSummary} from "../../models/BankingAccounts";
import {DropdownGroup} from "xps-react";

type AssetSummaryBankingAccountProps = {
    bankingAccount: BankingAccountsSummary,
    onClickEdit: (id: string) => void;
    onClickDelete: (bankingAccount: BankingAccountsSummary) => void;
    onClickViewHoldings: (id: string) => void;
    renderBankingAccountDetails: (bankingAccount: BankingAccountsSummary) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string;
    hideActionMenu?: boolean
};
export const BankingAccountRow = ({
                                       bankingAccount,
                                       onClickEdit,
                                      onClickDelete,
                                       onClickViewHoldings,
                                       renderBankingAccountDetails,
                                       actionsDisabled,
                                       gridClassName,
                                       hideActionMenu = false
                                   }: AssetSummaryBankingAccountProps) => {

    const presentValue = bankingAccount.eodNtBalance;
    const releaseToggles = useAppSelector(selectReleaseToggles);

    return (<div key={`held-away-row-${bankingAccount?.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={bankingAccount.accountName}>
        {renderBankingAccountDetails(bankingAccount)}
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${bankingAccount.accountName} Menu`}
                                     disabled={actionsDisabled}>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Edit Banking Account"
                        value="Edit Banking Account"
                        onClick={() => onClickEdit(bankingAccount?.id)}
                        disabled={!releaseToggles?.enableBankAccountAsset}
                    />
                    <DropdownItem
                        itemText="Edit Banking Holdings"
                        value="Edit Banking Holdings"
                        onClick={() => onClickViewHoldings(bankingAccount?.id)}
                    />
                </DropdownGroup>

                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Delete Account"
                        value="Delete Account"
                        //disabled={isProfileWithProposalsOrArchived}
                        onClick={() => onClickDelete(bankingAccount)}
                    >
                        <Icon name="delete" className="account-table-delete-icon"/>
                    </DropdownItem>
                </DropdownGroup>
            </TableActionDropdownMenu>}
    </div>);
};