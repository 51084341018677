import React, {ReactNode, useContext} from 'react';
import {AccordionItemWithActionMenu} from "../../../components";
import AccountsTable from "./AccountsTable";
import {Accounts, AssetsSummary, InvestmentProgramSummary} from "../../models/Assets";
import FutureInflowsTable from "./FutureInflowsTable";
import {AssetAccordionHeader} from "../common/AssetAccordionHeader";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EQUITY_COMPENSATIONS,
    COLOR_FUTURE_INFLOWS,
    COLOR_LIFE_INSURANCES,
    COLOR_PERSONAL_ASSETS
} from "../../../constants/colors";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {
    calculateAllAccountsInEstateInvestableTotalValue,
    calculateAllAccountsInEstateTotalMarketValue,
    calculateInEstateEquityCompensationTotalValue,
    calculatePersonalAssetInEstateTotalValue, calculateTotalValue
} from "../common/AssetSummaryCalculator";
import {DeleteModalProps} from "../../common/AssetsView";
import {assetListData, hasInEstateAssets, sortByOrdinalAndAfterTaxVestedValue} from "../common/utils";
import {useHistory} from "react-router-dom";
import {EquityCompensationFormData} from "../../models/EquityCompensation";
import AssetsViewContext from "../../common/AssetsViewContext";
import AccountActionMenu from "./AccountActionMenu";
import PersonalAssetsTable from "./PersonalAssetsTable";
import {LifeInsurancesTable} from "./LifeInsurancesTable";
import EquityCompensationTable from './EquityCompensationsTable';
import {isAnyGeneralInflowPendingDisclosureReview} from "../../GeneralInflow/InflowUtils";
import {GeneralInflow} from "../../models/GeneralInflow";
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

export type AssetListProps = {
    assetsData: AssetsSummary,
    investorGroup: InvestorGroupType,
    profileId: string,
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void,
    onRemoveInvestmentProgram: () => void,
    onRefreshInvestmentProgramHoldings: () => void,
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void,
    memberGroup?: MemberGroup
};

export default function AssetList({
                                      assetsData,
                                      investorGroup,
                                      profileId,
                                      onDeleteAssetClick,
                                      onRemoveInvestmentProgram,
                                      onRefreshInvestmentProgramHoldings,
                                      onSaveReviewTrustDisclosure,
                                      memberGroup
                                  }: AssetListProps) {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profileState = useAppSelector(selectProfile);

    function calculateTotalInvestableValueForAccounts(accounts: Accounts,
                                                      investmentProgram: InvestmentProgramSummary | null,
                                                      isHeldAwayAccountToShow?:boolean) {
        return calculateAllAccountsInEstateInvestableTotalValue(accounts.data, investmentProgram, isHeldAwayAccountToShow);
    }

    const {
        getFormattedTotalPresentValue,
        calculateTotalPresentValueForFutureInflows,
        hasFutureInflowAssets,
        hasInEstateAccount,
        hasInEstateBankingAccount,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstateStandaloneAccounts,
        inEstatePersonalAssets,
        inEstateLifeInsurances,
        inEstateEquityCompensations: inEstateEquityCompensation,
        hasInEstateInvestmentProgramHeldAA,
        inEstateHeldAwayAccounts
    } = assetListData(assetsData);

    const allItems = [
        hasInEstateAccount ? "Accounts" : '',
        hasInEstateBankingAccount ? "Banking Accounts" : '',
        hasFutureInflowAssets ? "FutureInflows" : '',
        inEstatePersonalAssets.length > 0 ? "PersonalAssets" : '',
        inEstateLifeInsurances.length > 0 ? "LifeInsurances" : '',
        inEstateEquityCompensation.length > 0 ? "EquityCompensations" : ''
    ].filter(header => header.length > 0);

    const handleEditLifeInsurance = (lifeInsuranceId: string) => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLifeInsurance/${lifeInsuranceId}`);
    };

    const onDeleteEquity = (equityComp: EquityCompensationFormData) => {
        onDeleteAssetClick({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetType: "equity compensation",
            assetDescription: equityComp.entityOrAccountName ?? "",
            assetId: equityComp.id,
        });
    }

    const onEditEquity = (equityCompId: string | undefined) => {
        return history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditEquityCompensation/${equityCompId}`);
    };

    const handleEditGeneralInflow = (assetId: string) => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditGeneralInflow/${assetId}`)
    }

    const handleEditSocialSecurity = (assetId: string) => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditSocialSecurity/${assetId}`);
    }

    const investmentProgramName = assetsData.investmentProgram ? assetsData.investmentProgram.name : '';
    const actionMenu: ReactNode = assetsData.investmentProgram || assetsData.partiallyOwnedLegalAgreements.length > 0
        ? <AccountActionMenu onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                             onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                             investmentProgramName={investmentProgramName}
                             isActiveClient ={profileState?.activeClient}
                             hasInvestmentProgram={assetsData.investmentProgram  !== null}
        />
        : undefined;

    const releaseToggles = useAppSelector(selectReleaseToggles);

    return <section data-testid="in-estate-asset-summary">
        <div className="asset-list">
            <div className="accordion-tables">
                <AccordionWithHeader
                    accordionId={"AssetSummaryAccordion"}
                    allItems={allItems}
                    columnTitles={["Asset Type", "Investable Value", "Present Value"]}
                    renderHeader={hasInEstateAssets(assetsData)}
                >
                    <div className="assets">
                        {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstatePartiallyOwnedInvestmentAccounts || hasInEstateInvestmentProgramHeldAA
                            || hasInEstateBankingAccount)
                            && <AccordionItemWithActionMenu
                                uuid="Accounts"
                                accentColor={COLOR_ASSETS_ACCOUNTS}
                                HeaderComponent={({expanded}) => {
                                    const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                                        assetsData.accounts.data,
                                        assetsData.investmentProgram,
                                        assetsData.partiallyOwnedLegalAgreements,
                                        );
                                    const totalInEstateInvestableValue = calculateTotalInvestableValueForAccounts(
                                        assetsData.accounts, assetsData.investmentProgram, releaseToggles?.enableHeldAwayAccountForAsset);
                                    return <AssetAccordionHeader
                                        expanded={expanded}
                                        title="Accounts"
                                        formattedTotalInvestableValue={getFormattedTotalPresentValue(totalInEstateInvestableValue)}
                                        formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                                        gridClassName={"assets-grid"}
                                    />;
                                }}
                                actionMenu={actionMenu}
                            >
                                <AccountsTable
                                    profileId={profileId}
                                    onDeleteAssetClick={onDeleteAssetClick}
                                    standaloneAccounts={inEstateStandaloneAccounts}
                                    legalAgreements={assetsData.investmentProgram?.legalAgreements || []}
                                    heldAwayAccounts={releaseToggles?.enableHeldAwayAccountForAsset && inEstateHeldAwayAccounts || []}
                                    partiallyOwnedInvestmentAccounts={inEstatePartiallyOwnedInvestmentAccounts}
                                    bankingAccounts={assetsData.bankingAccounts}
                                />
                            </AccordionItemWithActionMenu>}


                        {inEstateEquityCompensation.length > 0 && <AccordionItemWithActionMenu
                            uuid="EquityCompensations"
                            accentColor={COLOR_EQUITY_COMPENSATIONS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title="Equity Compensation"
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateInEstateEquityCompensationTotalValue(inEstateEquityCompensation))}
                                    gridClassName={"assets-grid"}
                                    customPresentValueLabel='After-Tax Total'
                                />
                            }
                        >
                            <div className="equity-compensation-section">
                                <EquityCompensationTable
                                    inEstateEquityCompensations={sortByOrdinalAndAfterTaxVestedValue(inEstateEquityCompensation) as EquityCompensationFormData []}
                                    editEquity={onEditEquity}
                                    deleteEquity={onDeleteEquity}
                                    memberGroup={memberGroup}
                                />
                            </div>
                        </AccordionItemWithActionMenu>}

                        {hasFutureInflowAssets && <AccordionItemWithActionMenu
                            uuid="FutureInflows"
                            accentColor={COLOR_FUTURE_INFLOWS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title={(
                                        <>
                                            Future Inflows
                                            {
                                                isAnyGeneralInflowPendingDisclosureReview(assetsData.generalInflows) &&
                                                <span aria-label="Disclosure needs review" role="alert"
                                                      className="disclosure-needs-review-alert">
                                                    <i className="dds-icons icon icon--warning align-self-start marginright-md"
                                                       aria-hidden="true">warning</i>
                                                    Disclosure needs review
                                                </span>
                                            }
                                        </>
                                    )}
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalPresentValueForFutureInflows())}
                                    gridClassName={"assets-grid"}
                                />
                            }
                        >
                            <div className="future-inflow-section">
                                <FutureInflowsTable
                                    generalInflows={assetsData.generalInflows}
                                    socialSecurities={assetsData.socialSecurities}
                                    investorGroup={investorGroup}
                                    onEditGeneralInflow={handleEditGeneralInflow}
                                    onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                                    onEditSocialSecurity={handleEditSocialSecurity}
                                    onDeleteAssetClick={onDeleteAssetClick}
                                />
                            </div>
                        </AccordionItemWithActionMenu>}

                        {inEstatePersonalAssets.length > 0 && <AccordionItemWithActionMenu
                            uuid="PersonalAssets"
                            accentColor={COLOR_PERSONAL_ASSETS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title="Personal Assets"
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculatePersonalAssetInEstateTotalValue(assetsData.personalAssets.data))}
                                    gridClassName={"assets-grid"}
                                />
                            }
                        >
                            <PersonalAssetsTable
                                inEstatePersonalAssets={inEstatePersonalAssets}
                                profileId={profileId}
                                viewType={viewType}
                                onDeleteAssetClick={onDeleteAssetClick}
                            />
                        </AccordionItemWithActionMenu>}

                        {inEstateLifeInsurances.length > 0 && <AccordionItemWithActionMenu
                            uuid="LifeInsurances"
                            accentColor={COLOR_LIFE_INSURANCES}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title="Life Insurance"
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalValue(assetsData.lifeInsurances.data))}
                                    gridClassName={"assets-grid"}
                                />
                            }
                        >
                            <LifeInsurancesTable lifeInsurances={inEstateLifeInsurances}
                                                 memberGroup={memberGroup}
                                                 onEdit={handleEditLifeInsurance}
                                                 onDelete={onDeleteAssetClick}/>
                        </AccordionItemWithActionMenu>}
                    </div>
                </AccordionWithHeader>
            </div>
        </div>
    </section>;
}
