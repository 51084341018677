import React, {useCallback, useEffect} from "react";
import {IAzureAudience} from "@fluidframework/azure-client";
import {selectActiveMeeting, setAudienceMembers} from "../meetingSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {meetingApiClient} from "../MeetingApiClient";
import {CommunicationsConnectionState, useCommunicationsContext} from "../CommunicationsContext";
import {getIdentifierRawId} from "@azure/communication-common";
import {RemoteParticipant} from "@azure/communication-calling";
import {useRelayContext} from "./types/RelayContext";


type RelayAudienceManagerProps = {
    audience: IAzureAudience | undefined,
}

export const RelayAudienceManager: React.FC<RelayAudienceManagerProps> = ({
                                                                              audience,
                                                                          }) => {
    if (!audience) {
        return <></>;
    }

    const dispatch = useAppDispatch();
    const {onlineMeetingId, profileIdToPresent, remoteEnabled} = useAppSelector(selectActiveMeeting)!;
    const {callManagers, connectionState} = useCommunicationsContext();
    const {sharedObjects} = useRelayContext();

    const updateParticipants = useCallback(() => {
        const localUserId = audience.getMyself()?.userId;
        meetingApiClient.getMeetingParticipants(profileIdToPresent, onlineMeetingId).then((allowedParticipants) => {
            const audienceMembers = audience.getMembers();
            const acsUsers = (callManagers?.call?.remoteParticipants || [])
                .reduce<Record<string, RemoteParticipant>>((users, user) => {
                    users[getIdentifierRawId(user.identifier)] = user;
                    return users;
                }, {});
            const activeMeetingParticipants = allowedParticipants
                .filter((meetingParticipant) => audienceMembers.has(meetingParticipant.userId))
                .filter((meetingParticipant) =>
                    meetingParticipant.userId === localUserId
                    || !meetingParticipant.communicationsId
                    || !!acsUsers[meetingParticipant.communicationsId]
                ).sort((a, b) => {
                    if (a.userId === localUserId) {
                        return -1;
                    }
                    if (b.userId === localUserId) {
                        return 1;
                    }
                    return 0;
                });
            dispatch(setAudienceMembers(activeMeetingParticipants));
            if (sharedObjects?.meetingParticipantDDS) {
                sharedObjects.meetingParticipantDDS.set('participants', activeMeetingParticipants);
            }
        });
    }, [audience, callManagers?.call, sharedObjects]);

    useEffect(() => {
        if (remoteEnabled && connectionState === CommunicationsConnectionState.DISCONNECTED) {
            return;
        }
        updateParticipants();
        const handleUpdateParticipants = () => updateParticipants();
        audience.on('membersChanged', handleUpdateParticipants);
        callManagers?.call?.on('remoteParticipantsUpdated', handleUpdateParticipants);
        return () => {
            audience.off('membersChanged', handleUpdateParticipants);
            callManagers?.call?.off('remoteParticipantsUpdated', handleUpdateParticipants);
        }
    }, [updateParticipants, remoteEnabled, connectionState]);

    return <></>;
}
