import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import AssetsViewContext from "../../common/AssetsViewContext";
import {assetsApiClient} from "../../AssetsApiClient";
import {BankAccountSearchResults} from "./BankingAccountSearchResults";
import {BankingAccountReadModel} from "../../models/BankingAccount";
import {BankingAccountSearch} from "./BankingAccountSearch";
import {useAppSelector} from "../../../store/hooks";
import {
    selectProfile,
    selectResourceCode
} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {ProfileType} from "../../../ClientManagement/models/ProfileResponse";
import {Icon} from "../../../components";
import {COLOR_NT_AQUA_800} from "../../../constants/colors";
import {EmptyStateContainer} from "../../../components";

export function AddBankingAccount() {
    const history = useHistory();
    const {profileId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);
    const [bankingAccounts, setBankingAccounts] = useState<BankingAccountReadModel[]>();
    const [searchedBankAccount, setSearchedBankAccount] = useState<BankingAccountReadModel[]>();
    const componentUnmounted = useRef(false);
    const resourceCode = useAppSelector(selectResourceCode);
    const profile = useAppSelector(selectProfile);

    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    useEffect(() => {
        let isProfile: boolean = profile?.type === ProfileType.PROFILE;
        Promise.all([
            // another ulek :: 1008631761, 1115558899
            assetsApiClient.getBankingAccounts(profileId, resourceCode, isProfile)
        ]).then(([
            bankingResponse ]) => {
            setBankingAccounts(bankingResponse);
            setSearchedBankAccount(bankingResponse);
        }).catch(error => console.error('Could not fetch banking accounts', error.message));
    }, [profileId]);

    const handleClickOnCancel = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}`);
    };

    const handleSearch = (search: string) => {
        const searchAccount = bankingAccounts?.filter(bankingAccount => bankingAccount.accountNumber === search);
        setSearchedBankAccount(searchAccount);
    }

    const handleSearchCleared = () => {
        setSearchedBankAccount(bankingAccounts);
    }

    return (
        <div className="banking-account">
            <DataEntryHeader
                title="Add Northern Trust Bank Account"
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={handleClickOnCancel}
                hidePrimaryButton
            />
            <div className="banking-account-container">
                <div className="search-bar-container marginbottom-lg">
                    <BankingAccountSearch
                        onSearch={handleSearch}
                        onSearchCleared={handleSearchCleared}
                    />
                </div>
                {
                    searchedBankAccount && searchedBankAccount.length ?
                        <BankAccountSearchResults bankingAccounts={searchedBankAccount}/> :
                        <EmptyStateContainer className="no-bank-accounts"
                                             title={'No matching accounts were found.'}
                                             size="large"/>
                }
            </div>
        </div>
    );
}